@charset "utf-8";



// Our variables
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     10px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "mixins/retina",
        "mixins/tipsy",
        "base",
        "layout",
        "syntax-highlighting",
        "socicons",
        "hospex-icons",
        "table",
        "btn",
        "tooltip"
;



.social-links {
  font-size: 19px;
  text-align: center;
  a {
    color: #444;
    transition: color .2s ease-in-out;
    -webkit-transition: color .2s ease-in-out;
    &:hover {
      text-decoration: none;
      color: $brand-color;
    }
  }
}
